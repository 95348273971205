import { createStore } from "vuex";

import page from './page.state'
import qrcodesState from "./qrcodes.state";
export default createStore({
  modules: {
    page: page,
    qrcode: qrcodesState
  }

});
