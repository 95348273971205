import { createApp } from 'vue'
import App from './App.vue'
import routes from './routes'
import store from './store'
import { CHANGE_URL } from './store/type.actions'
import axios from "axios";


const app = createApp(App)

routes.beforeEach((to, from, next) => {
    //store.dispatch(CHECK_AUTH),
    if( to.fullPath === from.fullPath) {next(); return;}
    //next();  
    Promise.all([store.dispatch(CHANGE_URL, to)]).then(next())
  }
  );

  axios.defaults.baseURL = '/';   
   //axios.defaults.baseURL = 'http://localhost:3000/';
app.use(store);
app.use(routes)
app.mount('#app')
