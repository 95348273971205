import { CHANGE_URL } from "./type.actions";
import { SET_PAGESTATE } from "./type.mutations";
const initialState = {
    isLandingPage: true
}

export const state = { ...initialState };

export const actions = {
    async [CHANGE_URL](context, url) {
        if( url )
            context.commit(SET_PAGESTATE, true);
        // if (url.fullPath === '/') {
        //     console.log('landing-page');
        //     context.commit(SET_PAGESTATE, true);
        // } else {
        //     console.log('not landing-page')
        //     context.commit(SET_PAGESTATE, false);
            
        // }
        return true;
    }
};

export const mutations = {
    [SET_PAGESTATE](state, value) {
        console.log('set landing-page')
        state.isLandingPage = value;
    }
};


const getters = {
    isLandingPage(state) {
        console.log('not landing-page')
        return state.isLandingPage;
    }
}


export default {
    state,
    actions,
    mutations,
    getters
};
