<!-- <template>
  <PageHeader/>
  <div v-if="!isLandingPage">
    <SideBar/>
  </div>
  <router-view></router-view>

  
</template> -->
<template>
  <div id="app">
    <router-view>
      
    </router-view>
  </div>
</template>


<script>
/*
import PageHeader from './components/Header.vue';
import SideBar from './components/SideBar.vue';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/demo.css'
import "material-icons/css/material-icons.min.css"
import '@material-icons/font/css/all.css'
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    PageHeader,
    SideBar
  },
  computed : {
    ...mapGetters(["isLandingPage"])
  }
}
  */
import '@material-icons/font/css/all.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/demo.css'
export default {
  name: 'App',
  methods: {
    handlePrimaryClick() {
    },
    handleSecondaryClick() {
      this.$router.push({
        name: 'qrcodes-links'
      })
    }
  }
}
</script>
<!-- 
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style> -->


<style scoped>
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .btn {
    width: 100vh;
    margin: 10px 0;
  }
}

.icon-new-qr {
  background-image: url('./assets/img/create-qr.png');
}

#app {
  height: 100vh;
  
}

</style>